#contact{
    font-size: 19px;
}


@media screen and (max-width: 830px){

    #contact{
        font-size: 15px;
    }
    

}