.port_text_container{
    width:auto;
    max-width: 500px;
    margin: 0px;
}

.port_block{
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 90%; 
    max-width: 800px;
}

.t_block_container{
    margin-bottom:10px;
    padding:20px;
}

.port_image_block{
    /* width:auto; */
    /* css for centered img */
    /* display: block; */
    /* margin-left: auto; */
    /* margin-right: auto; */
    /* width: 50%; */
    width:100%;
    
}