#clock-container {
    /* font-family: 'Share Tech Mono', monospace; */
    text-align: center;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: #ffffff;
    text-shadow: 0 0 20px rgb(230, 10, 219);
    z-index: 11;
    width:100%;
    pointer-events: none;
}
    .App-title {
        letter-spacing: 0.05em;
        font-size: min(5vw,75px);
        padding: 5px 0;
    }
    .clock {
        letter-spacing: 0.1em;
        font-size: 44px;
        /* text-align: left; */
        display: inline-block;
        font-size: min(3vw, 50px);
            font-family: 'Share Tech Mono', monospace;
    }

