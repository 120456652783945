body {
  min-height: 100vh;
  margin: 0;
  overflow-x: hidden;
  overflow-y:hidden;
  font-family: 'Raleway', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-tap-highlight-color:  rgba(255, 255, 255, 0); 
  overflow-anchor: auto;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.background{
  background: rgb(93,4,148);
  background: linear-gradient(90deg, rgb(143, 20, 201) 6%, rgb(57, 110, 179) 40%, rgb(57, 110, 179) 60%, rgb(73 26 183) 100%);
  /* background: linear-gradient(180deg, rgb(137, 0, 223) 0%, rgb(113, 90, 224) 50%, rgb(7, 124, 219) 100%); */

  background-repeat: no-repeat;
  background-size: cover;
  width: 100vw;
  height: 100vh;
  z-index: -10;
  position: fixed;
}

html{
  position: relative;
  min-height: 100vh;
  overflow-y:scroll;
}

/* html, body {
    height: 100%;
    width: 100%;
} */

/* html,
body {
   margin:0;
   padding:0;
   height:100%;
} */

.body {
  display: flex;
  flex-direction: column;
  margin:0;
  min-height: 100vh;
}

