



.scroll-to-top-container{
    /* margin-left: 90%; */
    margin-bottom: 15px;
}


.scroll-to-top{
    margin:40px auto 0;
    display:block;
    cursor: pointer;
    width:40px;
    height:40px;
  
    /* transform: rotate(-90deg); */
}
/* .scroll-to-top path{
    pointer-events: none;
} */
/* .scroll-to-top circle{
    fill:#00000057;
    transition: fill 0.2s ease-in-out;
} */

.scroll-to-top path{ 
    transition: all 0.2s ease-in-out; 
}

.scroll-to-top:hover{ 
    color: white;
  
}
.scroll-to-top__text{
    margin:0px auto 4%;
    text-align: center;
    font-size: 25px;
    cursor: pointer;
}

