
.image_block{
    width:300px;
    height:300px;
    margin:0px;
}


.t_block_container.centered{
  text-align: center;
  /* margin: auto; */
  position: absolute;
  width:80vw;
  max-width: 800px;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.t_block_container span{
    color: #c7ffd5;
    /* font-weight: bold; */
    text-shadow: 0 0 0 black;
}


.flex_side{
    display:flex;
    margin: 50px 0px;
    align-items:center;
    gap:40px;
    justify-content:space-evenly;
}

.t_block_container.single p{
    margin:0px;
}

.t_block_container{
    margin:50px 0px;
    background-color: rgb(29, 29, 29);
    color: #ffffff;
    border-radius: 10px;
    width:100%;
    /* min-width:300px; */
    /* text-align: center; */
    /* margin:50px 30px; */
    font-size: 1em;
    font-family: 'Raleway', sans-serif;
    padding:30px;
}

/* .t_block_container p{
    margin-bottom:20px;
} */


.page_container{
    width:60%;
    min-width: 700px;
    margin:0px auto;
}


.t_block_container.regular{
    margin:0px auto;
    background-color: rgb(29, 29, 29);
    color: #ffffff;
    /* border-radius: 10px; */
    width:100%;
    max-width: 1000px;
    /* min-width:300px; */
    /* text-align: center; */
    /* margin:50px 30px; */
    font-size: 1em;
    font-family: 'Raleway', sans-serif;
    padding:30px;
}

@media screen and (max-width: 830px){
    .t_block_container{
        width:100%;
        min-width:0px;
        font-size: min(3.9vw, 1em);
        line-height: 1.7em;
        padding:10px;
    }

  

    /* .page_container.contact{
        margin:auto;
        height:auto;
    } */

    .page_container{
        width:90%;
        min-width: 0px;
    }

    .t_block_container.regular{
        min-width:0px;
        width:auto;
        font-size: min(2.8vw, 1em);
        line-height: 1.7em;
        padding:10px;
        margin-left:15px;
        margin-right:15px;
    }

    .image_block{
        width:30vw;
        height:30vw;
    }

    .flex_side{
        display:flex;
        /* margin: 50px 0px; */
        align-items:center;
        gap:10px;
        justify-content:space-evenly;
    }
    
}

.t_block_text{
    margin-bottom:20px;   
}